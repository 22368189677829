
import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import certificat from "../../assets/img/telechargement/CERTIFICAT_DAGREMENT_CORSAF.png"


const CertificatPage = () => (
  <Layout>
    <SEO title="CORSAF Certificat d'agrément" />
   <div class="grid grid-cols-1 mt-8 gap-5 md:mx-40 md:my-5 mx-3"> 
     <h1 class="text-center text-2xl font-bold uppercase">Certificat d'agrément</h1>
     <div class="bg-gradient-to-r from-white via-red-700 to-white h-0.5"></div>
     <div><img src={certificat} alt="Certificat d'agrément" /></div>
   </div>
   </Layout>

  
)

export default CertificatPage